import phone from '../../../../assets/img/phone_dating_avatars2.png'
import { Button } from '../../../../components/ui'
import { QrSection } from '../../../../components/QrSection'
import { appStoreLink, googlePlayLink } from '../../../../constants/links'

import './styles.scss'
import getAppLink from '../../../../utils/getAppLink'

export const StartScreenSection = () => {

  return (
    <>
      <main className="start-screen section-block">
        <div className="container">
          <div className="start-screen--texts">
            <div className="start-screen__header">
              <h1>New generation social ecosystem</h1>
            </div>
          </div>
          <div className="start-screen--btns">
            <a
              className="start-screen--btn"
              href={getAppLink()}
              target="_blank"
              rel="noreferrer"
            >
              <Button content="start using" />
            </a>
          </div>
          {/* <div className="start-screen__logos">
          <div className="start-screen__logos-container">
            <a href={appStoreLink}>
              <img src={AppStoreLogo} alt="app store logo" />
            </a>
            {appStoreQR}
          </div>
          <div className="start-screen__logos-container">
            <a href={googlePlayLink}>
              <img src={googlePlayLogo} alt="google play logo" />
            </a>
            {googlePlayQR}
          </div>
        </div> */}
        </div>
      </main>
      <section className="mainScreenSection">
        <div className="container">
          <div className="mainScreenSection--block">
            <p>Relagram</p>
            <h2>
              Realize your potential everywhere with the Relagram mobile app
            </h2>
            <QrSection />
          </div>
          <div className="mainScreenSection--block">
            <img src={phone} alt="phone" />
          </div>
        </div>
      </section>
    </>
  )
}
