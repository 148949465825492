import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'
import closeIcon from '../../assets/svg/icon_close.svg'

import './styles.scss'
import { FC } from 'react'

interface VideoPresentationProps {
  video: string
  onClose: () => void
}

export const VideoPresentation: FC<VideoPresentationProps> = ({
  video,
  onClose,
}) => {
  return (
    <div className="video-presentation" onClick={(e) => {
      e.stopPropagation()

      if ((e.target as Element).localName === 'div') {
        onClose()
      }
    }}>
      <div className="video-presentation--video-popup">
        <span onClick={onClose}>
          <img src={closeIcon} alt="" />
        </span>
        <video controls src={video} autoPlay>
          <track kind="captions" />
        </video>
        {/* <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/aGSYKFb_zxg"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        /> */}
      </div>
    </div>
  )
}
